.spinner {
  height: 12em;

  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2.5em;
    height: 2.5em;
    margin-left: -1.25em;
    margin-top: -1.25em;
  }

  &.is-large {
    > * {
      width: 5em;
      height: 5em;
      margin-left: -2.5em;
      margin-top: -2.5em;
    }
  }
}
