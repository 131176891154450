._opponents {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    ._opponent {
        position: absolute;
        transition: transform .3s, top .3s, left .3s, right .3s;

        color: rgba(255,255,255,.8);

        ._hand {
            position: relative;
        }

        .name {
            span {
                font-weight: 600;
            }
            div {
                display: inline-block;
                font-weight: 300;
                margin-left: 5px;
            }
        }

        &.left .name {
            transform: rotate(-90deg);
            position: absolute;
            top: -26%;
            left: calc(50% - 20px);
            width: auto;
        }

        &.right .name {
            transform: rotate(90deg);
            position: absolute;
            top: -26%;
            left: calc(50% - 40px);
            width: auto;
        }

        &.top .name {
            transform: rotate(180deg);
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            margin-top: -16%;
        }
    }
}