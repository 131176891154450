._deck {
    position: fixed;
    bottom: 0;
    left: 0;

    transform-origin: top left;
    transform: translateX(-40%) translateY(70%) rotate(-45deg);

    min-height: 2vh;
    min-width: 2vw;

    color: white;

    > :not(.lastCard) {
        position: absolute;
        top: 0;
        left: 0;
    }

    .lastCard {
        transform: translateX(30%) rotate(90deg);
    }
}