._card {
    display: inline-block;
    width: 150px;

    margin-right: 10px;

    cursor: pointer;

    transition: transform .3s, top .3s, left .3s, right .3s;
    
    img {
        transition: transform .1s;
        width: 100%;
    }
    
}

.playField ._card.selected {
    transform: translateY(50px);

    img {
        transform: rotate(0deg) !important
    }
}

.hand ._card.selected {
    transform: translateY(-50px);

    img {
        transform: rotate(0deg) !important
    }
}