._attacks {
    position: fixed;
    left: 10vw;
    right: 10vw;
    top: 13.5vh;
    text-align: center;

    ._attack {
        position: relative;
        display: inline-block;
        width: 150px;
        height: 200px;

        margin-right: 8vw;
        margin-top: 10vh;

        ._card {
            width: 100%;
            position: absolute;
            &.defendingCard {
                transform: rotate(20deg);
            }
        }
    }
}


@media screen and (max-width: 960px) {
    ._attacks {
        transform: scale(.75);
    }
}