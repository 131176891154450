body {
  background-color: $colorBackground;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;

  font-family: "Cetury Gothic", "Helvetica", "Verdana";


  &.notify {
    background-image: linear-gradient(to bottom right, $colorBackground, $colorBackground3);
    background-size: 600% 600%;

    -webkit-animation: AnimationName 2.5s ease infinite;
    -moz-animation: AnimationName 2.5s ease infinite;
    animation: AnimationName 2.5s ease infinite;
    
    @keyframes AnimationName {
        0%{background-position:0% 0%}
        50%{background-position:100% 0%}
        100%{background-position:0% 0%}
    }
    @-webkit-keyframes AnimationName {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
    }
    @-moz-keyframes AnimationName {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
    }

  }
}

.container,
.container .title,
.container ._game,
.box {
  color: white;
  background: transparent !important;
  text-align: center;
  box-shadow: none;
}

hr {
  border: 0 !important;
  height: 1px;
  background: $colorFont;
  opacity: .25;
}

.button.is-primary {
  background: rgba(255,255,255,.5) !important;
  border: 0 !important;
}

button:disabled {
  opacity: .4 !important;
}

.error {
  text-align: center;
  color: $color6;
}

.vignette {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 500px rgba(0,0,0,0.9) inset;
}

.notifier {
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 40px;
  width: 40px;
}

.timeoutedPlayer {
  position: fixed;
  z-index: 100;
  text-align: center;
  margin: auto;
  width: 100%;
  left: 0;
  top: 0;
  background: red;
  padding: 20px;
}


.notifier {
  visibility: hidden;
}


@media only screen 
  and (max-device-width: 640px) {
    .left {
      position: fixed;
      display: none;
    }
    .right {
      margin-top: 0;
      transform: 0;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 100;
      text-align: left;

      .button.action {
        width: 1px;
        padding-left: 40px !important;
        overflow: hidden;
        color: transparent;
      }
    }
}



