._hand {
    position: absolute;

    transition: transform .3s, top .3s, left .3s, right .3s;

    // background-color: rgba(255,0,0,.5);
    
    ._card {
        position: absolute;
        &:hover {
        }
    }
}