._controls {
  position: fixed;
  text-align: center;
  right: 1vw;
  width: 100vw;
  bottom: 20vh;
  color: rgba(255,255,255,.8) !important;

  .button {
    width: 200px;
  }
  .button.shove {
    margin-left: 1.5rem;
  }

  .title {
    font-weight: 300;
    color: rgba(255,255,255,.8) !important;
  }

  ._trumpColor {
    line-height: 30px;
    height: 30px;
    vertical-align: top;

    ._card {
      margin: 0 0 0 0;
      margin-top: 1.5rem;
      display: inline-block;
      width: 30px;
      opacity: .25;
    }
  }
}
