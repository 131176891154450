// colors
$color1: rgba(37, 35, 35, 1);
$color2: rgba(80, 128, 142, 1);
$color3: rgba(245, 241, 237, 1);
$color4: rgba(148, 201, 169, 1);
$color5: rgba(132, 95, 131, 1);
$color6: rgba(196, 88, 86, 1);
$color7: rgba(244, 214, 115, 1);


// bulma overrides
/*
    $primary: $color2;
    $link: $color6;
    $info: $color2;
    $success: $color4;
    $warning: $color7;
    $danger: $color6;
    $dark: $color1;
    $text: $color1;
*/

$colorBackground: #1e483a; //#064355; // rgba(5, 85, 70, 1);
$colorBackground2: $color2;
$colorBackground3: $color4;
$colorFont: rgba(255, 255, 255, 1);